import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { Hero, Section, Box, Button } from '../components/bulma';
// import Hero from '../components/Hero';
// import Slideshow from '../components/Slideshow';
import Portfolio from '../components/Portfolio';
import FeaturedBlog from '../components/FeaturedBlog';
import Tags from '../components/Tags';
// import Logos from '../components/Logos';
// import Blurbs from '../components/Blurbs';
import ContentSection from '../components/ContentSection';
import FinalSection from '../components/FinalSection';
import BlogSection from '../components/BlogSection';
import DigestSection from '../components/DigestSection';
import CTA from '../components/CTA';

export default function IndexPage({ data }) {
	const { markdownRemark: page } = data;
	const { image, heading, subheading, sections, blurbs, final } = page.frontmatter;
	console.log(page.frontmatter.image);

	return (
		<Layout>
			<IndexPageTemplate
				image={image}
				heading={heading}
				subheading={subheading}
				blurbs={blurbs}
				sections={sections}
				final={final}
			/>
		</Layout>
	);
}

export const IndexPageTemplate = ({ image, heading, subheading, sections, blurbs, final }) => (
	<div>
		{/* <Section> */}

		{/* <section className="hero is-light is-fullheight">
					<div className="hero-body">
						<div className="container">
							<h1 className="title">Fullheight title</h1>
							<h2 className="subtitle">Fullheight subtitle</h2>
						</div>
					</div>
				</section> */}
		<Hero bold color="dark" size="large" background={image}>
			<h1 className="title" style={{ fontSize: '96px' }}>
				{heading}
			</h1>
			<h2 className="subtitle">{subheading}</h2>
			{/* <Button size="medium" color="dark">
						Contact
					</Button> */}
		</Hero>
		<Section>
			<Portfolio />
		</Section>
		<Section>
			<FeaturedBlog />
			<Tags />
		</Section>

		{/* </Section> */}
		{/* <Blurbs box items={blurbs} /> */}
		{/* <Section>
			<ContentSection items={sections} />
		</Section> */}

		{/* <BlogSection box /> */}
		{/* <Section>
			<h2 className="title is-size-2 has-text-centered">FinTech Digest</h2>
			<DigestSection box />
		</Section> */}
		{/* <Section>
			<CTA />
		</Section> */}
	</div>
);

export const indexPageQuery = graphql`
	query IndexPage {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				heading
				subheading
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				blurbs {
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					title
					text
				}
				sections {
					title
					text
					color
					side
					image {
						childImageSharp {
							fluid(maxWidth: 240, quality: 64) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
				final {
					title
					text
					# image {
					# 	childImageSharp {
					# 		fluid(maxWidth: 240, quality: 64) {
					# 			...GatsbyImageSharpFluid_withWebp
					# 		}
					# 	}
					# }
				}
			}
		}
	}
`;
