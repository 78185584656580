import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Box, Card, CardImage, CardContent, CardFooter, Column, Columns, Section } from './bulma';

export default function FeaturedBlog({ box, size, items }) {
	const data = useStaticQuery(BLOG_HEADLINES_QUERY);
	const { edges: posts } = data.allMarkdownRemark;

	console.log(posts.length);


	const { node: featuredPost } = posts?.pop();

	return (
		<div className="tile is-ancestor">
			<div class="tile is-vertical">
				<div class="tile">
					<div class="tile is-parent">
						<article class="tile is-child notification">
							<h2 class="title">How to get started with Payment.Ninja WooCommerce plugin</h2>
							<h3 class="subtitle">
								Install Payment.Ninja's free WooCommerce plugin and start make your WooCommerce store
								more profiatble instatly
							</h3>
							<iframe
								width="100%"
								height="315"
								src="https://www.youtube-nocookie.com/embed/AguT22rogkk?rel=0"
								frameborder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							/>
						</article>
					</div>
					<div class="tile is-parent">
						<article class="tile is-child notification">
							<p class="title">{featuredPost.frontmatter.title}</p>
							<p class="subtitle">Read our latest blog post</p>
							<Card>
								<CardImage>
									{featuredPost.frontmatter.featuredimage ? (
										<PreviewCompatibleImage
											imageInfo={{
												image: featuredPost.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${featuredPost.title}`
											}}
										/>
									) : null}
								</CardImage>
								<CardContent>
									<br />

									<p className="subtitle">{featuredPost.frontmatter.description}</p>

									<br />
									<Link to={featuredPost.fields.slug} className="button is-primary">
										Continue reading
									</Link>
								</CardContent>
							</Card>
						</article>
					</div>
				</div>
				<div class="tile">
					{posts.slice(1, ++items).map(({ node: post }) => (
						<div key={post.id} className="tile is-parent">
							<article className="tile is-child notification">
								<Card>
									<CardImage>
										{post.frontmatter.featuredimage ? (
											<PreviewCompatibleImage
												imageInfo={{
													image: post.frontmatter.featuredimage,
													alt: `featured image thumbnail for post ${post.title}`
												}}
											/>
										) : null}
									</CardImage>
									<CardContent>
										<br />

										<p className="post-meta">
											<Link className="title is-size-4" to={post.fields.slug}>
												{post.frontmatter.title}
											</Link>
										</p>
										<br />
										<Link to={post.fields.slug} className="button is-small is-dark">
											Continue reading
										</Link>
									</CardContent>
								</Card>
							</article>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

const BLOG_HEADLINES_QUERY = graphql`
	query FeaturedBlogQuery {
		allMarkdownRemark(
			limit: 10
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
		) {
			edges {
				node {
					excerpt(pruneLength: 400)
					id
					fields {
						slug
					}
					frontmatter {
						title
						description
						templateKey
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 250, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
