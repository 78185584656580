import React from 'react';
import { kebabCase } from 'lodash';
import { Link, graphql, useStaticQuery } from 'gatsby';

export default function Tags() {
	const data = useStaticQuery(TAGS);
	const { allMarkdownRemark: { group } } = data;

	return (
		<section>
			<div class="columns is-multiline is-mobile">
				{group.map((tag) => (
					<div className="column is-narrow" key={tag.fieldValue}>
						<h4 className="tag is-dark title is-size-7">
							<Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
								<span className="has-text-white">
									{tag.fieldValue} ({tag.totalCount})
								</span>
							</Link>
						</h4>
					</div>
				))}
			</div>
		</section>
	);
}

export const TAGS = graphql`
	query Tags {
		allMarkdownRemark(limit: 1000) {
			group(field: frontmatter___tags) {
				fieldValue
				totalCount
			}
		}
	}
`;
