import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Box, ButtonGroup, Button, Columns, Column } from './bulma';

export default function Portfolio() {
	const data = useStaticQuery(PORTFOLIO_QUERY);
	const { edges: items } = data.allMarkdownRemark;

	return (
		<div class="tile is-ancestor">
			{
				items?.map(({ node: item }) => (

						<div class="tile is-parent"  key={item.id}>
							<article class="tile is-child">
								{/* <div style={{ width: 150 }}>
								{item.frontmatter.image ? (
									<PreviewCompatibleImage
										imageInfo={{
											image: item.frontmatter.image,
											alt: `featured image thumbnail for item ${item.title}`
										}}
									/>
								) : null}
							</div> */}
							<br/>
								<p class="title is-size-4 is-spaced">{item.frontmatter.title}</p>
								<p class="subtitle">{item.frontmatter.description}</p>
								<ButtonGroup>
								{/* <Link to={item.fields.slug}>
									<Button size="small" outlined color="black">
										Learn more
									</Button>
								</Link> */}
								{/* <a href={`https://${item.frontmatter.url}`}>
									<Button color="text">Visit website →</Button>
								</a> */}
							</ButtonGroup>
							</article>
						</div>




				))}
		</div>
	);
}

const PORTFOLIO_QUERY = graphql`
	query Portfolio {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "portfolio-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
						url
						templateKey
						heading
						description
						image {
							childImageSharp {
								fluid(maxWidth: 250, quality: 100) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	}
`;
